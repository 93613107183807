<template>
<div class="category">
  <span class="select">
    <select v-model="rowData.category_id" v-on:change="onChange">
      <option v-for="option in categories" v-bind:value="option.id">
        {{ option.name }}
      </option>
    </select>
  </span>
  <!-- <a class="button is-outlined" @click="itemAction('view-item', rowData, rowIndex)">
      <i class="fa fa-search"></i>
    </a> -->
</div>
</template>

<script>
import RestResource from '../services/hackount'
import { mapGetters } from 'vuex'

export default {
  props: {
    rowData: {
      type: Object,
      required: true
    },
    rowIndex: {
      type: Number
    }
  },
  data: () => ({
    errors: []
  }),
  methods: {
    onChange(event) {
      const target = event.target
      const selection = target.options[target.selectedIndex].value
      const rr = new RestResource()

      rr.getHTTP().put(`/api/records/${this.rowData.id}/category/${selection}`)
        .then(response => { /* give feedback */ })
        .catch(e => {})
    }
    // itemAction(action, data, index) {
    //   console.log('custom-actions: ' + action, data.id, index)
    // }
  },
  computed: {
    ...mapGetters([
      'categories'
    ])
  }
}
</script>

<style>
.custom-actions button.ui.button {
  padding: 8px 8px;
}

.custom-actions button.ui.button>i.icon {
  margin: auto !important;
}
</style>
