<template>
  <div id="app">
    <!-- <img src="./assets/logo.png"> -->
    <my-vuetable></my-vuetable>
  </div>
</template>

<script>
import MyVuetable from "./components/MyVuetable";

export default {
  name: "app",
  components: {
    MyVuetable,
  },
  beforeCreate() {},
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
