<template>
  <div class="container">
    <div class="columns is-centered" style="margin-bottom: 1.5rem">
      <div class="column">
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Account name</th>
              <th>Account holder</th>
              <th>Current balance</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="account in accounts" :key="account.id">
              <th>{{ account.name }}</th>
              <td>{{ account.holder }}</td>
              <td>{{ account.initial_balance }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import RestResource from "../services/hackount";

export default {
  data() {
    return {
      accounts: [],
    };
  },
  methods: {
    async getAccounts() {
      const rr = new RestResource();
      const response = await rr.getHTTP().get(`/api/accounts`);
      this.accounts = response.data;
    },
  },
  async mounted() {
    await this.getAccounts();
  },
};
</script>

<style></style>
