// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import router from './services/router'
import store from './services/store'

import accounting from 'accounting'
import moment from 'moment'

Vue.filter('date', function (value) {
  return moment(value).format('DD-MM-YYYY')
})

Vue.filter('month', function (value) {
  return moment(value, "YYYYMM").format('MMMM')
})

Vue.filter('money', function (value) {
  return accounting.formatMoney(value, "€&nbsp;", 2, ".", ",")
})

/* eslint-disable no-new */
new Vue({
  router,
  store
}).$mount('#app')
