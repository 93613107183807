<template>
  <div>
    <div class="container">
      <div class="field is-horizontal">
        <div class="field-body">
          <div class="field">
            <p class="control">
              <span class="select is-small">
                <select v-model="year" v-on:change="refreshChart">
                  <option value="2017">Année 2017</option>
                  <option value="2018">Année 2018</option>
                  <option value="2019">Année 2019</option>
                  <option value="2020">Année 2020</option>
                  <option value="2021">Année 2021</option>
                  <option value="2022">Année 2022</option>
                  <option value="2023">Année 2023</option>
                  <option value="2024">Année 2024</option>
                </select>
              </span>
            </p>
          </div>
          <div class="field">
            <div class="control">
              <label class="radio">
                <input
                  type="radio"
                  value="by_year"
                  v-model="mode"
                  v-on:change="refreshChart"
                />
                By year
              </label>
              <label class="radio">
                <input
                  type="radio"
                  value="by_month"
                  v-model="mode"
                  v-on:change="refreshChart"
                  checked
                />
                By month
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="main-chart"></div>
    </div>
    <div class="overlay" :class="[drawerVisible ? 'visible' : '']"></div>
    <div
      @focusout="closeDrawer"
      tabindex="-1"
      class="drawer"
      ref="drawer"
      :class="[drawerVisible ? '' : 'close']"
    >
      <div class="container">
        <div class="columns is-centered" style="margin-bottom: 1.5rem">
          <div class="column">
            <table class="table is-fullwidth">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Properties</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="record in records" :key="record.id">
                  <th>{{ record.date | date }}</th>
                  <td>
                    <strong>Description:</strong>
                    {{ record.description || record.label }}
                    <span v-if="record.notes">
                      <br />
                      <strong>Notes:</strong> {{ record.notes }}
                    </span>
                  </td>
                  <td v-html="$options.filters.money(record.value)"></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Taucharts from "taucharts";
import "taucharts/dist/plugins/tooltip";
import "taucharts/dist/plugins/legend";
import "taucharts/dist/plugins/category-filter";
import RestResource from "../services/hackount";

export default {
  data() {
    return {
      drawerVisible: false,
      year: 2024,
      records: [],
      chart: null,
      mode: "by_month",
    };
  },
  methods: {
    closeDrawer() {
      this.drawerVisible = false;
    },
    async showRecords(data) {
      const rr = new RestResource();
      const result = await rr
        .getHTTP()
        .get(`/api/categories/${data.category_id}/records`, {
          params:
            this.mode === "by_month"
              ? {
                  year: data.month.substring(0, 4),
                  month: data.month.substring(4, 6),
                }
              : { year: this.year },
        });
      this.records = result.data;
      this.drawerVisible = true;
      this.$refs.drawer.focus();
    },
    async createChart(chartId) {
      const vm = this;
      this.chart = new Taucharts.Plot({
        data: await vm.getData(),
        spec: {
          unit: {
            type: "COORDS.RECT",
            x: "value",
            y: vm.mode === "by_month" ? "month" : null,
            guide: {
              showGridLines: "xy",
              padding: {
                l: 56,
                b: 46,
                r: 8,
                t: 8,
              },
            },
            unit: [
              {
                type: "ELEMENT.INTERVAL",
                color: "category_name",
                flip: true,
                label: "label",
              },
            ],
          },
        },
        asyncRendering: true,
        plugins: [
          Taucharts.api.plugins.get("legend")(),
          Taucharts.api.plugins.get("category-filter")(),
          Taucharts.api.plugins.get("tooltip")({
            clickable: false,
            fields: [
              vm.mode === "by_month" ? "month" : "year",
              "category_name",
              "balance",
              "value",
            ],
          }),
          {
            init(chart) {
              this._chart = chart;
            },
            onRender() {
              var elementsToMatch = [
                "ELEMENT.LINE",
                "ELEMENT.AREA",
                "ELEMENT.PATH",
                "ELEMENT.INTERVAL",
                "ELEMENT.INTERVAL.STACKED",
                "ELEMENT.POINT",
              ];

              this._chart
                .select((node) => {
                  return elementsToMatch.indexOf(node.config.type) >= 0;
                })
                .forEach((node) => {
                  node.on("data-click", (sender, e) => {
                    if (e.data) {
                      vm.showRecords(e.data);
                    }
                  });
                });
            },
          },
        ],
      });
      const element = document.getElementById(chartId);
      element.innerHTML = "";
      this.chart.renderTo(element);
    },
    async getData() {
      const rr = new RestResource();
      const categories = await rr
        .getHTTP()
        .get(`/api/reports/${this.year}/categories`, {
          params: { mode: this.mode },
        });
      const data_in = categories.data.items
        .map(({ month, category_id, category_name, sum, positive }) => ({
          month,
          year: this.year,
          category_id,
          category_name,
          balance: parseFloat(sum) || 0,
          value: parseFloat(positive) || 0,
          label: category_name + " (" + (parseFloat(positive) || 0) + ")",
        }))
        .filter((e) => e.value > 0);
      const data_out = categories.data.items
        .map(({ month, category_id, category_name, sum, negative }) => ({
          month,
          year: this.year,
          category_id,
          category_name,
          balance: parseFloat(sum) || 0,
          value: parseFloat(negative) || 0,
          label: category_name + " (" + (parseFloat(negative) || 0) + ")",
        }))
        .filter((e) => e.value < 0);
      return data_in.concat(data_out);
    },
    async refreshChart() {
      await this.createChart("main-chart");
    },
  },
  async mounted() {
    await this.createChart("main-chart");
  },
};
</script>

<style lang="scss">
.tau-chart__tooltip__button .tau-icon-close-gray {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSIzMHB4IiBoZWlnaHQ9IjMwcHgiIHZpZXdCb3g9IjAgMCAzMCAzMCI+PHBhdGggZmlsbD0iIzg0OTZBNyIgZD0iTTEwLDAuNzE1TDkuMjg1LDBMNSw0LjI4NUwwLjcxNSwwTDAsMC43MTVMNC4yODUsNUwwLDkuMjg1TDAuNzE1LDEwTDUsNS43MTVMOS4yODUsMTBMMTAsOS4yODVMNS43MTUsNUwxMCwwLjcxNXoiLz48L3N2Zz4=);
  display: inline-block;
  width: 12px;
  height: 12px;
  position: relative;
  top: 3px;
  margin-right: 5px;
}
.tau-chart__layout__sidebar-right__wrap {
  display: flex;
}
.drawer {
  &.close {
    height: 0;
    margin-top: 0;
  }
  background-color: #fff;
  transition: height 1s, margin-top 1s;
  position: sticky;
  bottom: 0;
  height: 70vh;
  margin-top: -70vh;
  overflow-y: scroll;
  z-index: 20;
}
.overlay {
  &.visible {
    z-index: 10;
    opacity: 0.5;
  }
  transition: opacity 1s;
  background: #000;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0;
  z-index: -99999;
}
</style>
