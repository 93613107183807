import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

import store from "./store";

import Login from "../components/Login";
import App from "../App";
import Import from "../components/Import";
import Accounts from "../components/Accounts";
import Chart from "../components/Chart";
import Balances from "../components/Balances";

const router = new Router({
  mode: "hash",
  routes: [
    // Each of these routes are loaded asynchronously, when a user first navigates to each corresponding endpoint.
    // The route will load once into memory, the first time it's called, and no more on future calls.
    // This behavior can be observed on the network tab of your browser dev tools.
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/accounts",
      name: "accounts",
      component: Accounts,
      beforeEnter: guardRoute,
    },
    {
      path: "/import",
      name: "import",
      component: Import,
      beforeEnter: guardRoute,
    },
    {
      path: "/records",
      name: "records",
      component: App,
      beforeEnter: guardRoute,
    },
    {
      path: "/chart",
      name: "chart",
      component: Chart,
      beforeEnter: guardRoute,
    },
    {
      path: "/balances",
      name: "balances",
      component: Balances,
      beforeEnter: guardRoute,
    },
  ],
});

function guardRoute(to, from, next) {
  // work-around to get to the Vuex store (as of Vue 2.0)
  // const auth = router.app.$options.store.state.auth

  if (!store.getters.isLoggedIn) {
    next({
      path: "/login",
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
}

export default router;
