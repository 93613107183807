import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import axios from 'axios';
import RestResource from './hackount'

const store = new Vuex.Store({
  state: {
    isLoggedIn: !!localStorage.getItem('token'),
    categories: []
  },
  actions: {
    login({
      commit
    }, creds) {
      commit('LOGIN')
      return axios.post('/api/auth', {
          username: creds.username,
          password: creds.password
        })
        .then(response => {
          localStorage.setItem('token', response.data.token)
          commit('LOGIN_SUCCESS')
        })
    },
    logout({
      commit
    }) {
      localStorage.removeItem('token');
      commit('LOGOUT');
    },
    loadCategories({
      commit
    }) {
      const rr = new RestResource()

      rr.getHTTP().get(`/api/categories`)
        .then(response => {
          commit('SET_CATEGORIES_LIST', { list: response.data })
        })
        .catch(e => {
          console.log(e)
        })
    }
  },
  mutations: {
    LOGIN: (state) => {
      state.pending = true;
    },
    LOGIN_SUCCESS: (state) => {
      state.isLoggedIn = true;
      state.pending = false;
    },
    LOGOUT: (state) => {
      state.isLoggedIn = false;
    },
    SET_CATEGORIES_LIST: (state, {
      list
    }) => {
      state.categories = list
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn
    },
    categories: state => {
      return state.categories
    },
  },
  modules: {

  }
})

export default store
