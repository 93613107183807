<template>
  <div class="filter-bar control is-grouped">
    <div class="field has-addons">
      <div class="control">
        <input
          id="search"
          type="text"
          v-model="filterText"
          class="input"
          @keyup.enter="doFilter"
          placeholder="Search…"
        />
      </div>
      <div class="control">
        <a @click="doFilter" class="button is-primary">Go</a>
      </div>
      <div class="control">
        <a @click="resetFilter" class="button">Reset</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["initialFilterText"],
  data() {
    return {
      filterText: this.initialFilterText || "",
    };
  },
  methods: {
    doFilter() {
      this.$events.fire("filter-set", this.filterText);
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.fire("filter-reset");
    },
  },
};
</script>
<style>
#search {
  width: 300px;
}
</style>
