import axios from 'axios';

export default class RestResource {

  getHTTP() {
    return axios.create({
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  import(accountId, formData) {
    return this.getHTTP()
      .post(`/api/accounts/${accountId}/import`, formData)
        .then(x => x.data)
  }

}
