<template>
  <section
    class="hero is-light is-fullheight"
    style="margin-top: -1.5rem; min-height: calc(100vh - 2rem)"
  >
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <form class="box" @submit.prevent="login">
              <div v-if="$route.query.redirect">
                <p>You need to login first.</p>
                <hr />
              </div>
              <div v-if="error">
                <p class="error">Bad login information</p>
                <hr />
              </div>
              <div class="field">
                <label for="" class="label">Username</label>
                <div class="control has-icons-left">
                  <input
                    type="text"
                    placeholder="e.g. bobsmith"
                    class="input"
                    required
                    v-model="email"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-user"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <label for="" class="label">Password</label>
                <div class="control has-icons-left">
                  <input
                    type="password"
                    placeholder="*******"
                    class="input"
                    required
                    v-model="pass"
                  />
                  <span class="icon is-small is-left">
                    <i class="fa fa-lock"></i>
                  </span>
                </div>
              </div>
              <div class="field">
                <button class="button is-success" type="submit">Login</button>
              </div>
              <form @submit.prevent="logout" v-if="isLoggedIn">
                <hr />
                <button type="submit">Logout</button>
              </form>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      pass: "",
      error: false,
    };
  },
  methods: {
    login() {
      this.$store
        .dispatch("login", {
          username: this.email,
          password: this.pass,
        })
        .then(() => {
          this.$router.push("/");
        })
        .catch(() => {
          this.error = true;
        });
    },
    logout() {
      this.$store.dispatch("logout");
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },
  },
};
</script>

<style>
.error {
  color: red;
}
</style>
