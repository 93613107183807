<template>
  <div class="container" style="margin-bottom: 1.5rem">
    <div class="columns is-centered">
      <div class="column">
        <nav class="level is-marginless">
          <div class="level-left">
            <div class="level-item">
              <filter-bar
                :initial-filter-text="$route.query.filter"
              ></filter-bar>
            </div>
          </div>
          <div class="level-right">
            <vuetable-pagination-info
              ref="paginationInfo"
            ></vuetable-pagination-info>
          </div>
        </nav>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column">
        <vuetable
          style="width: 100%"
          ref="vuetable"
          api-url="api/records"
          :http-options="options"
          :fields="fields"
          :css="css"
          :multi-sort="true"
          multi-sort-key="alt"
          :sort-order="sortOrder"
          detail-row-component="my-detail-row"
          :append-params="moreParams"
          :initial-page="parseInt($route.query.page) || 0"
          @vuetable:cell-clicked="onCellClicked"
          @vuetable:pagination-data="onPaginationData"
          :per-page="20"
        ></vuetable>
      </div>
    </div>
    <div class="columns is-centered">
      <div class="column">
        <bulma-pagination
          ref="pagination"
          @vuetable-pagination:change-page="
            (e) => onChange($route.query.filter, e)
          "
        ></bulma-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Vuetable from "vuetable-2/src/components/Vuetable";
import BulmaPagination from "./BulmaPagination";
import VuetablePaginationInfo from "vuetable-2/src/components/VuetablePaginationInfo";
import Vue from "vue";
import VueEvents from "vue-events";
Vue.use(VueEvents);
import Category from "./Category";
import DetailRow from "./DetailRow";
import FilterBar from "./FilterBar";

Vue.component("category", Category);
Vue.component("my-detail-row", DetailRow);

export default {
  components: {
    Vuetable,
    BulmaPagination,
    VuetablePaginationInfo,
    FilterBar,
  },
  data() {
    return {
      css: {
        tableClass: "table is-bordered is-striped",
        ascendingIcon: "fa fa-chevron-up",
        descendingIcon: "fa fa-chevron-down",
        sortHandleIcon: "fa fa-bars",
      },
      // add account name
      // limit number of chars description
      fields: [
        {
          name: "__checkbox",
          title: "#",
          titleClass: "has-text-centered",
          dataClass: "has-text-centered",
        },
        {
          name: "date",
          sortField: "date",
          titleClass: "has-text-centered",
          dataClass: "has-text-centered",
          callback: "formatDate",
        },
        {
          name: "account_holder",
          title: "Holder",
          sortField: "account_holder",
        },
        {
          name: "account_name",
          title: "Account",
          sortField: "account_name",
        },
        {
          name: "title",
          title: "Description",
          callback: "truncate",
        },
        {
          name: "value",
          sortField: "value",
          titleClass: "has-text-centered",
          dataClass: "has-text-right",
          callback: "formatMoney",
        },
        {
          name: "__component:category",
          title: "Category",
          titleClass: "has-text-centered",
          dataClass: "has-text-centered",
          sortField: "category_name",
        },
      ],
      sortOrder: [
        {
          field: "date",
          sortField: "date",
          direction: "desc",
        },
      ],
      moreParams: {},
      options: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      },
    };
  },
  methods: {
    transform(data) {
      for (let i = 0; i < data.data.length; i++) {
        data.data[i]["title"] =
          data.data[i]["description"] || data.data[i]["label"];
      }
      return data;
    },
    formatMoney(value) {
      return Vue.filter("money")(value);
    },
    formatDate(value) {
      return Vue.filter("date")(value);
    },
    truncate(value) {
      return value.slice(0, 50) + (50 < value.length ? "..." : "");
    },
    onPaginationData(paginationData) {
      this.$refs.pagination.setPaginationData(paginationData);
      this.$refs.paginationInfo.setPaginationData(paginationData);
    },
    onCellClicked(data) {
      this.$refs.vuetable.toggleDetailRow(data.id);
    },
    onChange(filterText, page = 1) {
      const query = {};
      if (filterText) {
        query["filter"] = filterText;
      }
      this.moreParams = { ...query };
      if (page === 1 && this.$refs.vuetable) {
        Vue.nextTick(() => this.$refs.vuetable.refresh());
      }
      if (page !== 1) {
        query["page"] = page;
        if (this.$refs.vuetable) {
          this.$refs.vuetable.changePage(page);
        }
      }
      if (JSON.stringify(query) !== JSON.stringify(this.$route.query)) {
        this.$router.replace({ query });
      }
    },
  },
  created() {
    this.onChange(this.$route.query.filter, this.$route.query.page);
  },
  mounted() {
    this.$store.dispatch("loadCategories");
    this.$events.listen("filter-set", (filterText) =>
      this.onChange(filterText)
    );
    this.$events.listen("filter-reset", () => this.onChange());
    this.$events.listen("table-reload", () => this.$refs.vuetable.reload());
  },
  beforeDestroy() {
    this.$events.remove("filter-set");
    this.$events.remove("filter-reset");
    this.$events.remove("table-reload");
  },
};
</script>
