<template>
  <div>
    <section
      class="hero is-light"
      style="margin-top: -1.5rem; margin-bottom: 1.5rem"
    >
      <div class="hero-body">
        <div class="container">
          <form
            name="upload"
            method="post"
            enctype="multipart/form-data"
            @submit.prevent="doImport"
          >
            <p v-if="uploaded">Done !</p>
            <div>
              <input type="file" name="file" id="file" />
            </div>
            <div>
              <span class="select">
                <select v-model="accountId" name="account">
                  <option value="4">Compte Hubert</option>
                  <option value="5">Compte commun</option>
                </select>
              </span>
            </div>
            <div>
              <button class="button" type="submit">Upload</button>
            </div>
          </form>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="columns is-centered" style="margin-bottom: 1.5rem">
        <div class="column">
          <table class="table" style="width: 100%">
            <thead>
              <tr>
                <th>Import</th>
                <th>Account holder</th>
                <th>Account name</th>
                <th>From</th>
                <th>To</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="imp0rt in imports" :key="imp0rt.id">
                <th>{{ imp0rt.id }}</th>
                <td>{{ imp0rt.holder }}</td>
                <td>{{ imp0rt.name }}</td>
                <td>{{ imp0rt.first_record_date | date }}</td>
                <td>{{ imp0rt.last_record_date | date }}</td>
                <td>
                  <a class="delete" v-on:click="deleteImport(imp0rt.id)"></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RestResource from "../services/hackount";

export default {
  data() {
    return {
      accountId: 1,
      uploaded: false,
      imports: [],
    };
  },
  methods: {
    doImport() {
      const formData = new FormData(document.forms["upload"]);

      formData.delete("account");

      const rr = new RestResource();
      const data = this;
      rr.import(this.accountId, formData).then(() => {
        data.uploaded = true;
        this.refreshImports();
      });
    },
    deleteImport(id) {
      const rr = new RestResource();

      if (confirm("Sure baby ?")) {
        rr.getHTTP()
          .delete(`/api/imports/${id}`)
          .then(() => {
            this.refreshImports();
          });
      }
    },
    refreshImports() {
      const rr = new RestResource();

      this.imports = rr
        .getHTTP()
        .get(`/api/imports`)
        .then((response) => {
          this.imports = response.data;
        });
    },
  },
  mounted() {
    this.refreshImports();
  },
};
</script>

<style>
.error {
  color: red;
}
</style>
